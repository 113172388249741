import { HttpParams } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  UntypedFormArray,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { EmployeeService } from "app/shared/api/employee.service";
import { Employee } from "app/shared/models/employee";
import { ShiftService } from "app/shared/api/shift.service";
import { ShiftJobService } from "app/shared/api/shiftjob.service";
import { Shift } from "app/shared/models/shift";
import { ShiftJob } from "app/shared/models/shiftjob";
import { ActivatedRoute } from "@angular/router";
import { ModalShiftJobsComponent } from "app/shifts/modal-shift-jobs/modal-shift-jobs.component";
import { ModalShiftTimesComponent } from "app/shifts/modal-shift-times/modal-shift-times.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MyFormControl } from "app/shared/validators/my-form-control.validator";

@Component({
  selector: "app-weekly-shift-template",
  templateUrl: "./weekly-shift-template.component.html",
  styleUrls: [
    "./weekly-shift-template.component.scss",
    "../../../../assets/sass/libs/select.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class WeeklyShiftTemplateComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() submitted = false;
  @Input() saveDisabled = false;

  @Input() columns: any[] = [];
  @Input() days: any = {
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
  };

  @Output() saveForm = new EventEmitter<any>();
  @Output() showSpinner = new EventEmitter<any>();
  @Output() hideSpinner = new EventEmitter<any>();

  employees: Employee[];
  shifts: Shift[];
  jobs: ShiftJob[];

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private shiftService: ShiftService,
    private employeeService: EmployeeService,
    private shiftjobService: ShiftJobService
  ) {}

  getDays() {
    return Object.keys(this.days);
  }

  fg(day, i) {
    return (<UntypedFormGroup>(
      (<UntypedFormGroup>this.form.get(day))?.controls[i]
    ))?.controls;
  }

  get sunday() {
    return this.form.get("sunday") as UntypedFormArray;
  }

  get monday() {
    return this.form.get("monday") as UntypedFormArray;
  }

  get tuesday() {
    return this.form.get("tuesday") as UntypedFormArray;
  }

  get wednesday() {
    return this.form.get("wednesday") as UntypedFormArray;
  }

  get thursday() {
    return this.form.get("thursday") as UntypedFormArray;
  }

  get friday() {
    return this.form.get("friday") as UntypedFormArray;
  }

  get saturday() {
    return this.form.get("saturday") as UntypedFormArray;
  }

  ngOnInit(): void {
    this.showSpinner.emit({});
    this.getEmployees();
    this.getJobs();
    this.getShifts();
  }

  getEmployees() {
    let params = new HttpParams()
      .set("no_page", ``)
      .set("profile__operator__has_attendance", `true`)
      .set("profile__operator__is_employee", `true`)
      .set("profile__operator__is_active", `true`);
    this.employeeService.find({ params }).subscribe(
      (data) => {
        //console.log(data);
        this.employees = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getJobs() {
    let params = new HttpParams().set("no_page", ``);
    this.shiftjobService.find({ params }).subscribe(
      (data) => {
        // console.log(data);
        this.jobs = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  
  getShifts() {
    let params = new HttpParams().set("no_page", ``);
    this.shiftService.find({ params }).subscribe(
      (data) => {
        //console.log(data);
        this.shifts = data;
        this.hideSpinner.emit({});
      },
      (error) => {
        console.log(error);
      }
    );
  }


  updateFormDaysArray(i, value) {
    this.sunday.controls[i].get("job").setValue(value);
    this.monday.controls[i].get("job").setValue(value);
    this.tuesday.controls[i].get("job").setValue(value);
    this.wednesday.controls[i].get("job").setValue(value);
    this.thursday.controls[i].get("job").setValue(value);
    this.friday.controls[i].get("job").setValue(value);
    this.saturday.controls[i].get("job").setValue(value);
  }

  onColumnJobChange(event, i): void {
    // console.log(event, i);
    if (event) {
      this.columns[i]["job"] = event["id"];
      this.updateFormDaysArray(i, event["id"]);
    } else {
      this.columns[i]["job"] = null;
      this.updateFormDaysArray(i, null);
    }
  }

  onColumnShiftChange(event, i, day): void {
    // console.log(event, i, day);
    if (event) {
      if (day === "sunday") {
        this.sunday.controls[i].get("shift").patchValue(event["id"]);
      } else if (day === "monday") {
        this.monday.controls[i].get("shift").patchValue(event["id"]);
      } else if (day === "tuesday") {
        this.tuesday.controls[i].get("shift").patchValue(event["id"]);
      } else if (day === "wednesday") {
        this.wednesday.controls[i].get("shift").patchValue(event["id"]);
      } else if (day === "thursday") {
        this.thursday.controls[i].get("shift").patchValue(event["id"]);
      } else if (day === "friday") {
        this.friday.controls[i].get("shift").patchValue(event["id"]);
      } else if (day === "saturday") {
        this.saturday.controls[i].get("shift").patchValue(event["id"]);
      }
    }
  }

  onColumnEmployeeChange(event, i, day): void {
    // console.log(event, i, day);
    if (event) {
      if (day === "sunday") {
        this.sunday.controls[i].get("employee").patchValue(event["id"]);
      } else if (day === "monday") {
        this.monday.controls[i].get("employee").patchValue(event["id"]);
      } else if (day === "tuesday") {
        this.tuesday.controls[i].get("employee").patchValue(event["id"]);
      } else if (day === "wednesday") {
        this.wednesday.controls[i].get("employee").patchValue(event["id"]);
      } else if (day === "thursday") {
        this.thursday.controls[i].get("employee").patchValue(event["id"]);
      } else if (day === "friday") {
        this.friday.controls[i].get("employee").patchValue(event["id"]);
      } else if (day === "saturday") {
        this.saturday.controls[i].get("employee").patchValue(event["id"]);
      }
    }
  }

  createDayFormGroup(id, date, job, shift, employee) {
    let controls = {
      job: [job, [Validators.required]],
      shift: [shift, [Validators.required]],
      employee: new MyFormControl(employee, []),
    };
    if (date !== null) {
      controls['date'] = [date, [Validators.required]];
    }
    if (id !== null) {
      controls['id'] = [id, [Validators.required]];
    }
    return this.formBuilder.group(controls);
  }

  onAddNewColumn(id, date, job, shift, employee) {
    this.columns.push({ job: job });
    this.sunday.push(this.createDayFormGroup(id, date, job, shift, employee));
    this.monday.push(this.createDayFormGroup(id, date, job, shift, employee));
    this.tuesday.push(this.createDayFormGroup(id, date, job, shift, employee));
    this.wednesday.push(this.createDayFormGroup(id, date, job, shift, employee));
    this.thursday.push(this.createDayFormGroup(id, date, job, shift, employee));
    this.friday.push(this.createDayFormGroup(id, date, job, shift, employee));
    this.saturday.push(this.createDayFormGroup(id, date, job, shift, employee));
  }

  onClearColumns() {
    this.columns = [];
    this.sunday.clear();
    this.monday.clear();
    this.tuesday.clear();
    this.wednesday.clear();
    this.thursday.clear();
    this.friday.clear();
    this.saturday.clear();
  }

  deleteColumn(i) {
    this.sunday.removeAt(i);
    this.monday.removeAt(i);
    this.tuesday.removeAt(i);
    this.wednesday.removeAt(i);
    this.thursday.removeAt(i);
    this.friday.removeAt(i);
    this.saturday.removeAt(i);
    this.columns.splice(i, 1);
  }


  onManageShiftTimes() {
    const modalRef = this.modalService.open(ModalShiftTimesComponent, {
      backdrop: 'static', keyboard: false,
      size: "lg",
    });
    modalRef.componentInstance.passEntry.subscribe((res) => {
      // console.log(res);
      this.getShifts();
    });
  }

  onManageShiftJobs() {
    const modalRef = this.modalService.open(ModalShiftJobsComponent, {
      backdrop: 'static', keyboard: false,
      size: "lg",
    });
    modalRef.componentInstance.passEntry.subscribe((res) => {
      // console.log(res);
      this.getJobs();
    });
  }

  copyCellToColumn(i) {
    const shift = this.monday.controls[i].get("shift").value;
    const employee = this.monday.controls[i].get("employee").value;
    console.log(shift, employee);
    if (shift && employee) {
      this.tuesday.controls[i].get("shift").patchValue(shift);
      this.tuesday.controls[i].get("employee").patchValue(employee);
      this.wednesday.controls[i].get("shift").patchValue(shift);
      this.wednesday.controls[i].get("employee").patchValue(employee);
      this.thursday.controls[i].get("shift").patchValue(shift);
      this.thursday.controls[i].get("employee").patchValue(employee);
      this.friday.controls[i].get("shift").patchValue(shift);
      this.friday.controls[i].get("employee").patchValue(employee);
      this.saturday.controls[i].get("shift").patchValue(shift);
      this.saturday.controls[i].get("employee").patchValue(employee);
      this.sunday.controls[i].get("shift").patchValue(shift);
      this.sunday.controls[i].get("employee").patchValue(employee);
    }

  }
}
