<div class="row justify-content-center">
  <button class="btn btn-info" [disabled]="saveDisabled" (click)="onAddNewColumn(null, null, null, null, null)">
    <i class="fa fa-plus"></i>
    {{ "Add Column" | translate }}
  </button>
  <button class="btn btn-danger ml-2" [disabled]="saveDisabled" (click)="onClearColumns()">
    <i class="fa-solid fa-eraser"></i>
    {{ "Clear Columns" | translate }}
  </button>
  <button class="btn btn-secondary ml-2" (click)="onManageShiftTimes()">
    <i class="fa-solid fa-clock"></i>
    {{ "Manage Shift Times" | translate }}
  </button>
  <button class="btn btn-secondary ml-2" (click)="onManageShiftJobs()">
    <i class="fa-solid fa-briefcase"></i>
    {{ "Manage Shift Jobs" | translate }}
  </button>
</div>
<ng-container [formGroup]="form">
  <div class="row mt-2">
    <div class="table-responsive">
      <table class="table table-bordered m-0">
        <thead class="thead-light">
          <tr>
            <th scope="col">{{ 'Day' | translate }}</th>
            <th scope="col" *ngFor="let column of columns; let i = index">
              <div class="row_data">
                <i class="fa-solid fa-trash" (click)="deleteColumn(i)"></i>
                <div class="form-group">
                  <ng-select class="auto-grow" [disabled]="saveDisabled" [items]="jobs" bindLabel="name" bindValue="id" [multiple]="false"
                    [closeOnSelect]="true" (change)="onColumnJobChange($event, i)"
                    [ngClass]="{ 'is-invalid': submitted && columns[i]['job'] === null, 'is-valid': submitted && columns[i]['job'] !== null }"
                    [(ngModel)]="columns[i]['job']" [ngModelOptions]="{standalone: true}"
                    [class.ng-untouched]="columns[i]['job'] === null" [class.ng-touched]="columns[i]['job'] !== null"
                    [class.ng-invalid]="!saveDisabled && columns[i]['job'] === null" [class.ng-valid]="!saveDisabled && columns[i]['job'] !== null"
                    [searchable]="true">
                  </ng-select>
                  <small class="form-text text-muted success" *ngIf="submitted && columns[i]['job'] !== null"><i
                      class="ft-check-circle align-middle"></i> {{ 'Looks Good'| translate }}</small>
                  <small class="form-text text-muted danger" *ngIf="submitted && columns[i]['job'] === null"><i
                      class="ft-alert-circle align-middle"></i> {{ 'Required'| translate }}</small>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let day of getDays(); let dayIndex = index">
            <tr [formArrayName]="day">
              <th scope="row" *ngIf="!days[day]">{{ day | titlecase | translate }}</th>
              <th scope="row" *ngIf="days[day]">{{ days[day] | localizedDate:'fullDate' }}</th>
              <td *ngFor="let column of columns; let i = index" [formGroupName]="i">
                <!-- errors: {{ fg(day, i)?.employee?.errors | json }}<br>
                warnings: {{ fg(day, i)?.employee?.warnings | json }}<br>
                submitted: {{submitted }} | invalid: {{ !fg(day, i)?.employee?.invalid }} | warnings: {{ !fg(day, i)?.employee?.warnings}}<br>
                id: {{ fg(day, i)?.id.value }} | date: {{ fg(day, i)?.date.value }} -->
                <div class="form-group">
                  <ng-select class="auto-grow mt-2" [items]="shifts" bindLabel="name" bindValue="id"
                    (change)="onColumnShiftChange($event, i, day)" formControlName="shift"
                    [ngClass]="{ 'is-invalid': submitted && fg(day, i).shift.invalid, 'is-valid': submitted && !fg(day, i).shift.invalid }"
                    [multiple]="false" [closeOnSelect]="true" [searchable]="true">
                  </ng-select>
                  <small class="form-text text-muted success" *ngIf="submitted && !fg(day, i).shift.invalid">
                    <i class="ft-check-circle align-middle"></i> {{ 'Looks Good'| translate }}
                  </small>
                  <small class="form-text text-muted danger" *ngIf="submitted && fg(day, i).shift.invalid">
                    <i class="ft-alert-circle align-middle"></i> {{ 'Required'| translate }}
                  </small>
                </div>
                <div class="form-group">
                  <ng-select class="auto-grow" [items]="employees" bindLabel="profile.operator.full_name"
                    (change)="onColumnEmployeeChange($event, i, day)" formControlName="employee"
                    [ngClass]="{ 'is-invalid': submitted && fg(day, i).employee.invalid, 'is-valid': submitted && !fg(day, i).employee.invalid && !fg(day, i).employee.warnings, 'is-warning': submitted && !fg(day, i).employee.invalid && fg(day, i).employee.warnings }"
                    bindValue="id" [multiple]="false" [closeOnSelect]="true" [searchable]="true"></ng-select>
                  <small class="form-text text-muted success"
                    *ngIf="submitted && !fg(day, i).employee.invalid && !fg(day, i).employee.warnings">
                    <i class="ft-check-circle align-middle"></i> {{ 'Looks Good'| translate }}
                  </small>
                  <small class="form-text text-muted danger"
                    *ngIf="submitted && fg(day, i).employee.invalid && fg(day, i).employee.hasError('required') && fg(day, i).employee.errors.required">
                    <i class="ft-alert-circle align-middle"></i> {{ 'Required'| translate }}
                  </small>
                  <small class="form-text text-muted danger"
                    *ngIf="submitted && fg(day, i).employee.invalid && fg(day, i).employee.hasError('employeeIsInHoliday') && fg(day, i).employee.errors.employeeIsInHoliday">
                    <i class="ft-alert-circle align-middle"></i> {{ 'Employee Is In Holiday'| translate }}
                  </small>
                  <small class="form-text text-muted danger"
                    *ngIf="submitted && fg(day, i).employee.invalid && fg(day, i).employee.hasError('employeeWorkMaxSixDays') && fg(day, i).employee.errors.employeeWorkMaxSixDays">
                    <i class="ft-alert-circle align-middle"></i> {{ 'Employee Max Six Days'| translate }}
                  </small>
                  <small class="form-text text-muted warning"
                    *ngIf="submitted && !fg(day, i).employee.invalid && fg(day, i).employee.getWarning('employeeWorkMaxTwoShiftPerDay') && fg(day, i).employee.warnings.employeeWorkMaxTwoShiftPerDay">
                    <i class="ft-alert-triangle align-middle"></i> {{ 'Max Employee In Shift Day'| translate }}
                  </small>
                  <small class="form-text text-muted warning"
                    *ngIf="submitted && !fg(day, i).employee.invalid && fg(day, i).employee.getWarning('employeeIsEmpty') && fg(day, i).employee.warnings.employeeIsEmpty">
                    <i class="ft-alert-triangle align-middle"></i> {{ 'Employee Is Empty'| translate }}
                  </small>
                </div>
                <div *ngIf="dayIndex == 0">
                  <i class="fa-solid fa-copy float-right text-primary" (click)="copyCellToColumn(i)" [title]="'Apply to other days of the week'| translate" style="cursor: pointer;"></i>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row justify-content-center mt-2">
    <button [disabled]="saveDisabled" class="btn btn-primary" type="submit">
      {{ "Save" | translate }}
    </button>
  </div>
</ng-container>